import { Link } from "gatsby";
import styled, { createGlobalStyle } from "styled-components";
import { theme } from "./theme";
import { SIZE } from "../constants/mediasQuery";
import Benefits from '../shared/components/Benefits/Benefits';
import { Assets } from "../constants/AssetsUrl";
const VariableTheme = theme;

const GlobalStyle = createGlobalStyle`
 * {
    font-family: ${VariableTheme.bodyFont};
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 0;
 }

 body, html {
    margin: 0;
    padding: 0;
    background-color: ${VariableTheme.coolGray100};
 }

 p {
    color: ${VariableTheme.coolGray800}
 }

 h1, h2, h3, h4, h5, h6 {
    font-family: ${VariableTheme.headyFont};
    font-weight: bold;
 }

 .btn-close {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: transparent;
    border: none;
  }

  .btn-what {
    background-color: ${VariableTheme.whatsapp};
    color: ${VariableTheme.coolGray700};
    height: 30px;
    border-radius: 30px;
    margin: 10px 0;
    padding: 8px 20px;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
  }

  .active-loading {
  overflow: hidden;
  }

  .logo-wash {
    width: 80px !important;
  }

  .logo-mechanic {
    width: 80px !important;
  }

  .logo-modal-wash {
    width: 200px !important;
  }

  .logo-modal-mechanic {
    width: 200px !important;
  }

  .header-plan {
    display: flex;
    justify-content: center;
    column-gap: 10px;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
  }

  .content-plan {
    min-height: 600px;
    width: 100%;
    max-height: 300px;
    box-shadow: 0 0 9px rgba(0,0,0,.2);
    padding: 20px;
    margin: 5px;
    border-radius: 8px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    align-content: center;
    position: relative;
    overflow: hidden;
    &.plan-pro {
      color: ${VariableTheme.white};
      .recomendado {
        position: absolute;
        color: ${VariableTheme.white};
        padding: 5px 80px;
        background-color: ${VariableTheme.primary.default};
        z-index: 2;
        transform: rotate(45deg);
        right: -71px;
        top: 50px;
      }
      &.plan-wash {
        background-color: #1DDCE3;
      }
      &.plan-mechanic {
        background-color: #FF8000;
      }
      @media (min-width: ${SIZE.DESKTOP}) {
        min-height: 600px;
      }
    }

     ul {
      min-height: 300px;
      max-height: 420px;
      overflow: hidden;
      margin-top: 20px;
      list-style: none;
      padding-left: 0;
      width: 100%;
      li {
        margin: 0;
        padding: 10px 0;
        font-size: .8rem;
        text-align: center;
        border-bottom: 1px solid ${VariableTheme.coolGray300};
        min-height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        &:first-child {
          border-top: 1px solid ${VariableTheme.coolGray300};
        }
      }
     }

     @media (min-width: ${SIZE.DESKTOP}) {
      min-height: 500px;
      margin: 0px;
     }
  }

`;

const FlexCenter = `
display: flex;
justify-content: center;
align-items: center;
align-content: center;`;

// Typographic

const HeavyTitle = styled.h1`
  font-size: calc(1.375rem + 1.5vw);
  line-height: 1.2;
  @media (min-width: ${SIZE.DESKTOP}) {
    font-size: 2.5rem;
    line-height: 2.6rem;
  }
`;
const Title = styled.h2`
  line-height: 1.2;
  font-size: calc(1.325rem + 0.9vw);
  @media (min-width: ${SIZE.DESKTOP}) {
    font-size: 2rem;
    line-height: 2.1rem;
  }
`;
const SubTitle = styled.h3`
  font-size: calc(1.3rem + 0.6vw);
  line-height: 1.2;
  @media (min-width: ${SIZE.DESKTOP}) {
    font-size: 1.75rem;
    color: ${VariableTheme.secondary.dark};
  }
`;
const CardTitle = styled.h4`
  font-size: calc(1.275rem + 0.3vw);
  line-height: 1.2;
  @media (min-width: ${SIZE.DESKTOP}) {
    font-size: 1.5rem;
    line-height: 1.6rem;
  }
`;
const CardSubTitle = styled.h5`
  font-size: 1.25rem;
  @media (min-width: ${SIZE.DESKTOP}) {
  }
`;
const MiniTitle = styled.h6`
  font-size: 1rem;
  @media (min-width: ${SIZE.DESKTOP}) {
  }
`;
const LinkCustom = styled(Link)`
  text-decoration: none;
  padding: 15px 0;
`;

const LinkOutCustom = styled(Link)`
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid ${VariableTheme.secondary.default};
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 250px;
  color: ${VariableTheme.primary.default};
  font-weight: bold;
  &:hover {
    background-color: ${VariableTheme.secondary.default};
    color: ${VariableTheme.white};
  }
`;

const BtnOutCustom = styled.button`
  text-decoration: none;
  padding: 5px 15px;
  border: 1px solid ${VariableTheme.secondary.default};
  height: 30px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
  max-width: 250px;
  color: ${VariableTheme.primary.default};
  font-weight: bold;
  &:hover {
    background-color: ${VariableTheme.secondary.default};
    color: ${VariableTheme.white};
  }
`;

const LinkMenu = styled(Link)`
  text-decoration: none;
  color: ${VariableTheme.coolGray800};
  font-family: ${VariableTheme.headyFont};
  font-weight: 400;
  &.active {
    color: ${VariableTheme.secondary.default};
  }
  @media (min-width: ${SIZE.DESKTOP}) {
    &:hover {
      color: ${VariableTheme.secondary.default};
    }
  }
`;

const LinkMenuExt = styled.a`
  text-decoration: none;
  color: ${VariableTheme.coolGray800};
  font-family: ${VariableTheme.headyFont};
  font-weight: 400;
  &.active {
    color: ${VariableTheme.secondary.default};
  }
  @media (min-width: ${SIZE.DESKTOP}) {
    &:hover {
      color: ${VariableTheme.secondary.default};
    }
  }
`;

const BtnMenu = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 15px 0;
  color: ${VariableTheme.coolGray800};
  font-family: ${VariableTheme.headyFont};
  font-weight: 400;
  position: relative;
  .btn-arrow {
    position: absolute;
    right: 30px;
    top: auto;
    bottom: auto;
    background-color: transparent;
    border: none;
  }
  &.active {
    color: ${VariableTheme.secondary.default};
  }
  @media (min-width: ${SIZE.DESKTOP}) {
    margin-right: 20px;
    &:hover {
      color: ${VariableTheme.secondary.default};
    }

    .btn-arrow {
      right: -10px;
      top: 19px;
    }
  }
`;

// Grilla

const MainLayout = styled.main`
  min-height: calc(100vh - 160px);
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const HeaderCustom = styled.header`
  width: auto;
  height: 40px;
  display: flex;
  padding: 10px;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 10;
  flex-direction: column;
  background-color: ${VariableTheme.white};
  box-shadow: 0 1px 3px #0000001a;
  @media (min-width: ${SIZE.DESKTOP}) {
    height: 40px;
    flex-direction: row;
  }
`;
const MenuMobile = styled.nav`
  width: calc(100vw - 30px);
  height: 100vh;
  padding: 15px;
  position: fixed;
  top: 0;
  animation: deslizarDerecha 0.5s ease;
  padding: 15px;
  background-color: ${VariableTheme.white};
  z-index: 12;

  @keyframes deslizarDerecha {
    from {
      transform: translateX(100vw);
    }
    to {
      transform: translateX(0);
    }
  }

  .brand {
    margin: 0px auto 15px;
    width: 100%;
  }

  .head-menu {
    width: auto;
    display: flex;
    justify-content: space-between;
  }
`;

const ContainerItemsMobile = styled.ul`
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  list-style: none;
  padding: 15px 0 0;
`;

const ContainerItems = styled.ul`
  width: auto;
  display: flex;
  column-gap: 20px;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  list-style: none;
  padding: 0;
`;

const ItemMobile = styled.li`
  border-bottom: 1px solid ${VariableTheme.coolGray300};
  min-height: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;

  .subItem {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    position: relative;
  }

  @media (min-width: ${SIZE.DESKTOP}) {
    border: none;
    &.subItem {
      &:hover {
        .subItemDesk {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: flex-start;
          text-align: left;
          position: absolute;
          top: 60px;
          width: auto;
          padding: 15px;
          border-radius: 8px;
        }
      }
    }
  }
`;

const ContainerSubItemMobile = styled.ul`
  width: 100%;
  display: flex;
  min-height: 50px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  list-style: none;
  padding: 0;
  background-color: ${VariableTheme.coolGray200};
  @media (min-width: ${SIZE.DESKTOP}) {
    border: none;

    &.subItemDesk {
      display: none;
    }
  }
`;

const SubItemMobile = styled.li`
  width: 100%;
  display: flex;
  height: 50px;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  list-style: none;
  padding: 0;
  background-color: ${VariableTheme.coolGray200};

  @media (min-width: ${SIZE.DESKTOP}) {
    justify-content: flex-start;
  }
`;

const PhoneCall = styled.span`
  width: 100vw;
  height: 60px;
  margin: -15px -15px 15px;
  padding: 15px;
  display: flex;
  background-color: ${VariableTheme.primary.default};
  color: ${VariableTheme.white};

  .btn-call {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.3);
    border: none;
    margin-right: 15px;
  }

  .phone-data {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    justify-content: center;
    align-items: flex-start;
    align-content: center;
  }
  @media (min-width: ${SIZE.DESKTOP}) {
    margin: 0;
    max-width: 280px;
    padding: 10px;
    height: 40px;

    .btn-call {
      width: 40px;
      height: 40px;
    }
    .phone-data {
      row-gap: 4px;
      > span {
        font-size: 0.8rem;
      }
    }
  }
`;

const MenuCustom = styled.nav`
  display: Flex;
  justify-content: space-between;
`;

const FooterCustom = styled.footer`
  background-color: ${VariableTheme.white};
  width: auto;
  min-height: 80px;
  display: flex;
  ${FlexCenter};
  flex-direction: column;
  padding: 30px;
  margin-bottom: 50px;
  border-top: 1px solid ${VariableTheme.coolGray300};
  @media (min-width: ${SIZE.DESKTOP}) {
    margin-bottom: 0px;
    flex-direction: row;
    flex-wrap: wrap;
    .container-footer {
      align-items: flex-start;
      column-gap: 10px;
    }
  }

  .head-footer {
    width: 100%;
    ${FlexCenter};
    flex-direction: column;
    padding-bottom: 20px;
    border-bottom: 1px solid ${VariableTheme.coolGray300};
    margin-bottom: 20px;
    .brand-footer {
      height: 40px;
      margin: 20px 0;
    }

    .title {
      margin: 30px 0;
    }

    .stores {
      ${FlexCenter};
      flex-direction: column;
      img {
        margin: 5px;
      }

      @media (min-width: ${SIZE.DESKTOP}) {
        flex-direction: row;
        img {
          margin: 5px;
          height: 40px;
        }
      }
    }

    @media (min-width: ${SIZE.DESKTOP}) {
      width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
    }
  }

  .data-brand {
    width: 100%;
    ${FlexCenter};
    flex-direction: column;
    text-align: center;
    padding: 15px 0;
    p {
      font-size: 0.8rem;
      margin-top: 15px;
    }

    @media (min-width: ${SIZE.DESKTOP}) {
      width: auto;
      max-width: 25%;
      align-items: flex-start;
      text-align: left;
    }
  }

  .data-link {
    width: 100%;
    ${FlexCenter};
    flex-direction: column;
    text-align: left;
    padding: 15px 0;
    ul {
      padding-top: 15px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
    }
    li {
      color: ${VariableTheme.secondary.default};
      width: 100%;
      text-align: left;
    }

    .item-link {
      color: ${VariableTheme.coolGray500};
      text-decoration: none;
      font-size: 0.8rem;
    }

    @media (min-width: ${SIZE.DESKTOP}) {
      width: auto;
      align-items: flex-start;
      text-align: left;
      ul {
        padding-left: 15px;
      }
    }
  }

  .data-newsletter {
    width: 100%;
    padding: 15px 0;
    ${FlexCenter};
    flex-direction: column;
    p {
      font-size: 0.8rem;
      margin-top: 15px;
      text-align: center;
    }
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 25%;
      align-items: flex-start;
      text-align: left;
      p {
        text-align: left;
      }
    }
  }

  .copyright {
    width: 100%;
    ${FlexCenter};
    flex-direction: column-reverse;
    border-top: 1px solid ${VariableTheme.coolGray300};
    padding: 15px 0 0;
    margin-top: 15px;
    .msj {
      > p {
        font-size: 0.8rem;
        color: ${VariableTheme.coolGray500};
        text-align: center;
      }
    }
    .term-cond {
      padding-left: 0;
      width: auto;
      ${FlexCenter};
      flex-direction: column;
      @media (min-width: ${SIZE.DESKTOP}) {
        flex-direction: row;
        justify-content: space-between;
        column-gap: 10px;
        align-items: center;
        align-content: center;
      }
      li {
        list-style: none;
        text-align: center;
        padding: 5px 0;
        &:last-child {
          padding: 5px 0 10px;
        }
        .item-copyright {
          font-size: 0.8rem;
          color: ${VariableTheme.coolGray500};
          text-decoration: none;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 100%;
      justify-content: space-between;
      flex-direction: row;
      .term-cond {
        li {
          &:last-child {
            padding: 5px 0;
          }
        }
      }
    }
  }
`;
const Container = styled.section`
  width: 100%;
  max-width: 1280px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  @media (min-width: ${SIZE.DESKTOP}) {
    justify-content: space-between;
    &.functions {
      justify-content: center;
    }
    &.pricing {
      justify-content: center;
    }
  }
`;

// brand

const Logo = styled.img`
  height: 30px;
  @media (min-width: ${SIZE.DESKTOP}) {
    height: 40px;
  }
`;

const ImgCustom = styled.img`
  height: 60px;
`;

// copmponent

const HeroSlider = styled.section`
  width: 100%;
  margin: auto;
  height: calc(100vh - 60px);
  .swiper {
    width: 100%;
    height: 100%;
    background-color: ${VariableTheme.white};
    &-button-prev, &-button-next {
      background: ${VariableTheme.secondary.default};
      height: 40px;
      width: 40px;
      border-radius: 50%;
      font-size: 20px;
      color: ${VariableTheme.white};
      font-weight: bold;
      display: none;
      &::after {
        font-size: 20px;
      }
      @media (min-width: ${SIZE.DESKTOP}) {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
      }

    }
  }



  .swiper-pagination-bullet-active {
    width: 16px;
    border-radius: 4px;
  }

  .swiper-slide {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

// ------------- Slider --------------------- //

interface SectionProps {
  backgroundImage: string;
  backgroundColor: string;
}

const SliderCenter = styled.section<SectionProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-color: ${(props) => props.backgroundColor};
  background-size: cover; // Cubrir completamente el área de la sección
  background-position: center; // Centrar la imagen de fondo
  background-repeat: no-repeat; // Repetir la imagen de fondo;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  @media (min-width: ${SIZE.DESKTOP}) {
    flex-direction: row-reverse;
  }
  .slider-container-center {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;
    .title {
      color: ${VariableTheme.secondary.default};
      text-align: center;
    }
    .detail {
      width: auto;
      background-color: rgb(0, 0, 0, 0.5);
      padding: 30px 30px 80px;
      .brand-logo {
        display: block;
        width: 300px;
        object-fit: contain;
        height: auto;
        margin: 30px auto;
        @media (min-width: ${SIZE.DESKTOP}) {
          width: 500px;
        }
      }
      @media (min-width: ${SIZE.DESKTOP}) {
        width: 100%;
        padding: 20px;
        border-radius: 10px;
        color: ${VariableTheme.white};
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
      color: ${VariableTheme.white};
      margin-top: 10px;
      font-size: 1rem;
      line-height: 1.2rem;
      font-family: ${VariableTheme.headyFont};
      @media (min-width: ${SIZE.DESKTOP}) {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.6rem;
      }
      strong {
        font-family: ${VariableTheme.headyFont};
        font-size: 1rem;
        @media (min-width: ${SIZE.DESKTOP}) {
          font-size: 1.5rem;
        }
        span {
          color: ${VariableTheme.secondary.default};
          font-family: ${VariableTheme.headyFont};
          font-size: 1rem;
          @media (min-width: ${SIZE.DESKTOP}) {
            font-size: 1.5rem;
          }
        }
      }
    }
    img {
      width: 300px;
    }
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 100%;
      max-width: 1280px;
      height: calc(100% - 60px);
      position: relative;
      align-items: flex-end;
      align-content: flex-end;
      justify-content: center;
      .title,
      p {
        text-align: center;
      }
    }
  }
  .graphic {
    padding: 30px;
    width: calc(100% - 60px);
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 50%;
      height: calc(100% - 60px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      > img {
        width: auto;
        object-fit: cover;
        height: 60%;
      }
    }
  }
`;

const SliderLeft = styled.section<SectionProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-color: ${(props) => props.backgroundColor};
  background-size: cover; // Cubrir completamente el área de la sección
  background-position: center; // Centrar la imagen de fondo
  background-repeat: no-repeat; // Repetir la imagen de fondo;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  @media (min-width: ${SIZE.DESKTOP}) {
    flex-direction: row-reverse;
  }
  .slider-container-left {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;
    .title {
      color: ${VariableTheme.secondary.default};
      text-align: center;
    }
    .detail {
      width: auto;
      background-color: rgb(0, 0, 0, 0.5);
      padding: 30px 30px 80px;
      margin-bottom: -30px;
      .brand-logo {
        height: 100px !important;
        object-fit: contain;
        width: auto;
        margin-bottom: 20px;
      }
      @media (min-width: ${SIZE.DESKTOP}) {
        width: 50%;
        padding: 20px;
        border-radius: 10px;
        color: ${VariableTheme.white};
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
      color: ${VariableTheme.white};
      margin-top: 10px;
      font-size: 1rem;
      line-height: 1.2rem;
      font-family: ${VariableTheme.headyFont};
      @media (min-width: ${SIZE.DESKTOP}) {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.6rem;
      }
      strong {
        font-family: ${VariableTheme.headyFont};
        font-size: 1rem;
        @media (min-width: ${SIZE.DESKTOP}) {
          font-size: 1.5rem;
        }
        span {
          color: ${VariableTheme.secondary.default};
          font-family: ${VariableTheme.headyFont};
          font-size: 1rem;
          @media (min-width: ${SIZE.DESKTOP}) {
            font-size: 1.5rem;
          }
        }
      }
    }
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 100%;
      max-width: 1280px;
      height: calc(100% - 60px);
      position: relative;
      align-items: flex-end;
      align-content: flex-end;
      justify-content: center;
      .title,
      p {
        text-align: left;
      }
    }
  }
  .graphic {
    padding: 30px;
    width: calc(100% - 60px);
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 50%;
      height: calc(100% - 60px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      > img {
        width: auto;
        object-fit: cover;
        height: 60%;
      }
    }
  }
`;

const SliderRight = styled.section<SectionProps>`
  background-image: url(${(props) => props.backgroundImage});
  background-color: ${(props) => props.backgroundColor};
  background-size: cover; // Cubrir completamente el área de la sección
  background-position: center; // Centrar la imagen de fondo
  background-repeat: no-repeat; // Repetir la imagen de fondo;
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  @media (min-width: ${SIZE.DESKTOP}) {
    flex-direction: row;
  }
  .slider-container-right {
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    width: 100%;
    height: 100%;
    .title {
      color: ${VariableTheme.secondary.default};
      text-align: center;
    }
    .detail {
      width: auto;
      background-color: rgb(0, 0, 0, 0.5);
      margin-bottom: -30px;
      padding: 30px 30px 80px;
      position: relative;
      .brand-logo {
        height: 100px !important;
        object-fit: contain;
        width: auto;
        margin-bottom: 20px;
      }
      @media (min-width: ${SIZE.DESKTOP}) {
        width: 50%;
        padding: 20px;
        border-radius: 10px;
        color: ${VariableTheme.white};
        margin-bottom: 0px;
      }
    }
    p {
      text-align: center;
      color: ${VariableTheme.white};
      margin-top: 10px;
      font-size: 1rem;
      line-height: 1.2rem;
      @media (min-width: ${SIZE.DESKTOP}) {
        margin-top: 20px;
        font-size: 1.5rem;
        line-height: 1.6rem;
      }
      font-family: ${VariableTheme.headyFont};
      strong {
        font-family: ${VariableTheme.headyFont};
        font-size: 1rem;
        @media (min-width: ${SIZE.DESKTOP}) {
          font-size: 1.5rem;
        }
        span {
          color: ${VariableTheme.secondary.default};
          font-family: ${VariableTheme.headyFont};
          font-size: 1rem;
          @media (min-width: ${SIZE.DESKTOP}) {
            font-size: 1.5rem;
          }
        }
      }
    }
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 100%;
      max-width: 1280px;
      height: calc(100% - 60px);
      position: relative;
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      justify-content: center;
      .title,
      p {
        text-align: left;
      }
    }
  }
  .graphic {
    padding: 30px;
    width: calc(100% - 60px);
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 50%;
      height: calc(100% - 60px);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      > img {
        width: auto;
        object-fit: cover;
        height: 60%;
      }
    }
  }
`;

const ButtonMenu = styled.button`
  background-color: transparent;
  border: none;
  .img {
  }
`;

const BtnCustom = styled.button`
  background-color: ${VariableTheme.primary.default};
  color: ${VariableTheme.white};
  border: none;
  border-radius: 20px;
  height: 40px;
  padding: 8px 20px;
  margin: 30px 10px;
  font-family: ${VariableTheme.headyFont};
  width: 100%;
  max-width: 320px;
`;

const BtnLinkCustom = styled(Link)`
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
  background-color: ${VariableTheme.secondary.default};
  color: ${VariableTheme.coolGray800};
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  font-size: 1rem;
  margin: 20px auto;
  font-family: ${VariableTheme.headyFont};
  width: 100%;
  max-width: 200px;
  @media (min-width: ${SIZE.DESKTOP}) {
    margin: 30px 10px;
  }
`;

const BtnWhatsApp = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: fixed;
  bottom: 0;
  z-index: 10;
  text-decoration: none;
  text-align: center;
  background-color: ${VariableTheme.whatsapp};
  color: ${VariableTheme.coolGray700};
  border: none;
  border-radius: 30px;
  padding: 5px 20px;
  font-family: ${VariableTheme.headyFont};
  width: calc(100% - 40px);
  height: 50px;
  .label-what {
    margin-left: 10px;
  }
  .icon-what {
    color: ${VariableTheme.white};
  }
  @media (min-width: ${SIZE.DESKTOP}) {
    margin: 30px 10px;
    width: 70px;
    height: 70px;
    border-radius: 60px;
    padding: 5px;
    right: 20px;
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    .label-what {
      display: none;
    }
    .icon-what {
      font-size: 2.7rem;
    }
  }
`;

const BtnLinkExtCustom = styled.a`
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
  background-color: ${VariableTheme.secondary.default};
  color: ${VariableTheme.white};
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  margin: 20px auto;
  font-family: ${VariableTheme.headyFont};
  width: 100%;
  max-width: 200px;
  @media (min-width: ${SIZE.DESKTOP}) {
    margin: 30px 10px;
  }
`;

const CardImg = styled.article`
  ${FlexCenter};
  flex-direction: column;
  width: 100%;
  max-width: 400px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .imgCard {
    padding: -15px -15px 0;
    width: 100%;
    height: 200px !important;
    object-fit: cover !important;
  }

  .content {
    > h4 {
      height: 80px;
      text-align: center;
      ${FlexCenter};
    }
    .text {
      height: 60px;
      margin: 10px 0;
      > p {
        display: -webkit-box;
        color: ${VariableTheme.coolGray500};
        font-size: 0.8rem;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    padding: 15px;
    ${FlexCenter};
    flex-direction: column;
  }
`;

const BtnLink = styled.button`
  background-color: transparent;
  color: ${VariableTheme.primary.default};
  border: none;
  width: 100%;
  text-align: left;
  font-size: 0.8rem;
`;

const FunctionSection = styled.section`
  width: auto;
  padding: 30px;
  background-color: ${VariableTheme.white};
  ${FlexCenter};
  flex-direction: column;
  .title-functions {
    text-align: center;
  }
`;

const BenefitsSection = styled.section`
  width: auto;
  padding: 30px;
  background-color: ${VariableTheme.secondary.default};
  ${FlexCenter};
  i {
    font-size: 2rem;
    width: 20px;
    height: 20px;
  }
  flex-direction: column;
  .title-benefits {
    text-align: center;
    color: ${VariableTheme.coolGray800};
  }

  .container-benefits {
    width: 100%;
    max-width: 1280px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 30px;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    padding: 30px;
  }

  .card-benefits {
    ${FlexCenter};
    flex-direction: column;
    background-color: ${VariableTheme.white};
    padding: 15px;
    box-shadow: 0 1px 3px #0000001a;
    width: 100%;
    max-width: 350px;
    border-radius: 8px;

    .title-card {
      margin-bottom: 10px;
    }
  }

  .icon-benefits {
    width: 100px;
    margin-bottom: 20px;
  }
`;

const SolutionsSection = styled.section`
  width: auto;
  padding: 30px 30px 60px;
  ${FlexCenter};
  flex-direction: column;
  gap: 4rem;

  .title-solutions {
    text-align: center;
    margin-bottom: 20px;
  }

  p {
    width: 100%;
    max-width: 1200px;
    text-align: center;
  }

  .solutions-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: flex-start;
    gap: 0.5rem;

     @media (min-width: ${SIZE.TABLET}) {
      flex-direction: row;
      gap: 3.5rem;
      flex-direction: row-reverse;
      padding-right: 3rem;

      &--odd {
        flex-direction: row;
      }
    }

    &__media {
      position: relative;
      line-height: 0;
      margin-bottom: 2rem;

      &-image {
        &::before {
          content: "";
          position: absolute;
          inset: 0;
          border-radius: 8px;
          border: 5px solid transparent;
          background: linear-gradient(140deg, rgba(255, 255, 255, 0.0), #00A3FE) border-box;
          -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
          -webkit-mask-composite: destination-out;
          mask-composite: exclude;
          left: 1rem;
          top: 1rem;
          bottom: -1rem;
          right: -1rem;
          padding-right: 1rem;
        }

        img {
          aspect-ratio: 16 / 9;
          border-radius: 8px;
          display: block;
          object-fit: cover;
          width: 100%;
        }
      }

      &-logo {
        position: absolute;
        background: #fff;
        bottom: 0;
        right: 0;
        padding: .5rem;
        border-radius: 7px 0;
        height: 3rem;
        width: 10rem;
        text-align: center;

        img {
          height: 100%;
          max-width: 100%;
        }
      }

      @media (min-width: ${SIZE.TABLET}) {
        height: 26rem;

        &-image, 
        &-image img {
          aspect-ratio: 0.7;
          height: 100%;
          width: auto;
        }
      }

      @media (min-width: ${SIZE.DESKTOP}) {
        height: 30rem;

        &-image, 
        &-image img {
          aspect-ratio: 0.85;
        }
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      max-width: 600px;

      &-detail, ul {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 3rem;
        text-indent: -1.1rem;
      }

      li {
        display: inline-block;
        vertical-align: middle;
      }

      li:before {
        content: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' color='%2300A3FE'%3E%3Cpath fill='currentColor' d='M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z'%3E%3C/path%3E%3C/svg%3E");
        display: inline-block;
        height: 1.1rem;
        width: 1.1rem;
        vertical-align: middle;
      }

      p {
        text-align: left;
      }
    }
  }
`;

const PricingSection = styled.section`
  width: auto;
  padding: 30px;
  background-color: ${VariableTheme.white};
  ${FlexCenter};
  flex-direction: column;
  .title-functions {
    text-align: center;
  }
`;

const GenericSlider = styled.section`
  &.pricing {
    min-height: 700px;
    margin-top: 30px;
  }

  width: 100%;
  height: 500px;
  @media (min-width: ${SIZE.DESKTOP}) {
    height: 500px;
    &.pricing {
      height: 700px;
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
    background-color: ${VariableTheme.white};
  }

  /* .swiper-wrapper {
    padding: 0 1px;
  } */

  .swiper-pagination-bullet-active {
    width: 16px;
    border-radius: 4px;
  }

  .swiper-slide {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    background-color: transparent;
  }

  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const ModalContainer = styled.div`
  .Modal {
    ${FlexCenter}
    .logo-wash {
      width: 200px !important;
    }

    .logo-mechanic {
      width: 200px !important;
    }
  }

  > p {
    margin: 30px 0;
    text-align: justify;
  }
`;

const HeroSplide = styled.section`
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  background-color: ${VariableTheme.coolGray100};
  padding-top: 30px;
  padding-bottom: 120px;

  @media (min-width: ${SIZE.DESKTOP}) {
    padding-bottom: 30px;
  }

  .title-services {
    width: 100%;
    text-align: center;
  }

  .tag-title {
    font-size: 0.8rem;
    color: ${VariableTheme.coolGray600};
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    height: 40px;
    line-height: 1rem;
    text-overflow: ellipsis;
  }

  .splide {
    &__track {
      overflow: visible;
    }

    &__arrows {
      position: absolute;
      right: 5px;
      bottom: -80px;
      display: flex;
      column-gap: 5px;
      width: 200px;
      height: 60px;
      @media (min-width: ${SIZE.DESKTOP}) {
        right: 5px;
        top: 20px;
      }
      .splide__arrow {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        border: 2px solid ${VariableTheme.secondary.default};
        background-color: transparent;
        padding: 10px;
        margin: 0;
      }
    }
  }
  .wrap-slipe {
    width: 100%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 8px;
    overflow: visible;
    position: relative;
    margin-top: 30px;
    @media (min-width: ${SIZE.DESKTOP}) {
      margin-top: 0;
    }
  }

  .go-splide {
    padding-top: 0px;
    width: 100%;
    max-width: 1200px;
    height: auto;
    overflow: visible;
    border-radius: 8px;
    background-color: transparent;
    clear: both;
    @media (min-width: ${SIZE.DESKTOP}) {
      padding-top: 100px;
    }
  }

  .card-splide {
    height: 100%;
    border-radius: 8px;
    justify-content: space-between;
    padding-left: 0;
    display: flex;
    flex-direction: column-reverse;
    box-shadow: 0 0 9px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    background-color: ${VariableTheme.white};
    margin-right: 20px;
    margin-left: 20px;
    @media (min-width: ${SIZE.DESKTOP}) {
      height: 400px;
      flex-direction: row;
      margin-right: 20px;
      margin-left: 0px;
    }
  }

  .card-content {
    width: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: space-around;

    @media (min-width: ${SIZE.DESKTOP}) {
      width: 50%;
      padding: 30px;
    }

    p {
     flex-grow: 1
    }

    h4 {
      height: 52px;
      margin-bottom: 10px;
    }

    .call-to-action {
      margin: 20px 0;
    }
  }

  .cont-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 200px;
    @media (min-width: ${SIZE.DESKTOP}) {
      width: 50%;
      height: 100%;
    }
    img {
      object-fit: cover;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      width: 100%;
      height: 200px;

      @media (min-width: ${SIZE.DESKTOP}) {
        clip-path: polygon(123px 0, 100% 0, 100% 100%, 0 100%);
        height: 100%;
      }
    }
  }
`;

const customStyles = {
  content: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
    margin: "auto",
    maxWidth: 400,
    maxHeight: 400,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(70, 70, 70, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
  },
};

const customStylesMobile = {
  content: {
    display: "flex",
    position: "absolute",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
    margin: 0,
    width: "calc(100vw-40px)",
    height: "calc(100vh-40px)",
    inset: 0,
  },
  overlay: {
    zIndex: 1000,
    backgroundColor: "rgba(70, 70, 70, 0.3)",
    display: "flex",
    justifyContent: "center",
    alignItem: "center",
    alignContent: "center",
  },
};

const TitlePageWrapper = styled.section`
  background-color: ${VariableTheme.white};
  height: 100px;
  padding: 20px;
  box-shadow: 0 1px 3px #0003;
  margin-bottom: 30px;
`;

export {
  GlobalStyle,
  customStyles,
  customStylesMobile,
  HeaderCustom,
  FooterCustom,
  MainLayout,
  HeavyTitle,
  TitlePageWrapper,
  Title,
  SubTitle,
  CardTitle,
  CardSubTitle,
  MiniTitle,
  HeroSlider,
  HeroSplide,
  Logo,
  Container,
  MenuCustom,
  MenuMobile,
  ButtonMenu,
  PhoneCall,
  ContainerItemsMobile,
  ContainerItems,
  ItemMobile,
  ContainerSubItemMobile,
  SubItemMobile,
  LinkMenu,
  LinkMenuExt,
  BtnOutCustom,
  LinkCustom,
  LinkOutCustom,
  BtnLinkCustom,
  ModalContainer,
  BtnLinkExtCustom,
  SolutionsSection,
  BenefitsSection,
  BtnMenu,
  SliderLeft,
  SliderRight,
  SliderCenter,
  CardImg,
  BtnCustom,
  BtnLink,
  FunctionSection,
  PricingSection,
  GenericSlider,
  BtnWhatsApp,
};
