export const theme = {
    white: "#FFFFFF",
    primary: {
        dark: "",
        default: "#10002B",
        light: ""
    },
    secondary: {
        dark: "#0092E0",
        default: "#00A3FE",
        light: ""
    },
    coolGray100: "#F7F7FC",
    coolGray200: "#EFF0F6",
    coolGray300: "#D9DBE9",
    coolGray400: "#A0A3BD",
    coolGray500: "#6E7191",
    coolGray600: "#4E4B66",
    coolGray700: "#262338",
    coolGray800: "#14142A",
    whatsapp: "#25d366",
    bodyFont: "Work Sans",
    headyFont: "Kanit",


}