module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ecccd6cb6020f00ad95fe23e5df26b53"},
    },{
      plugin: require('../node_modules/gatsby-plugin-apollo/gatsby-browser.js'),
      options: {"plugins":[],"uri":"https://cms.gosolutions.app/graphql/","headers":{"Authorization":"Bearer 2506ab74ad9b9445e41ad4d4d369a0d05bd8e1c0efbe3f3b4f1487bcf72431beb132929b74f7d1a54d93ff09e951e28681433ba08877f27ae90a631c9715cb1a1b6a9826b4650232bb049c4e99a6d5625f301f52bce507426a3dd5e38973cae4213f9c1f0b82d06938340ba8c150d9daa4026eb49670004f3bf7dffafec564de"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Go Solutions App","short_name":"Go Solutions","description":"The application does cool things and makes your life better.","lang":"es","display":"standalone","icon":"src/images/icon.png","icons":[{"src":"/images/icon-192.png","sizes":"192x192","type":"image/png"},{"src":"/images/icon-512.png","sizes":"512x512","type":"image/png"}],"start_url":"/","background_color":"#FFFFFF","theme_color":"#00A3FE","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"752aab5fdeed7dc6159f900318396edf"},
    },{
      plugin: require('../node_modules/gatsby-omni-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"enableListener":true,"preconnect":["https://fonts.googleapis.com","https://fonts.gstatic.com"],"web":[{"name":"Kanit","file":"https://fonts.googleapis.com/css?family=Kanit:ital,wght@0..1,100..900&display=swap&subset=latin"},{"name":"Work Sans","file":"https://fonts.googleapis.com/css?family=Work+Sans:ital,wght@0..1,100..900&display=swap&subset=latin"}]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
